import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MemberTypeCh } from '@proxy/cheetah/client/enum';
import { CustomerKfOnlyDto } from '@proxy/dto-common-website/customers';
import { CustomerService as ColleagueCustomerService } from '@proxy/user-casto/customers';
import { EnvService } from 'src/app/_services/env.service';
import { regexPostalCodeFr } from 'src/app/utils/constants';
import { Context } from 'src/app/utils/context';
import { Country, otherCountries, prioCountries } from 'src/app/utils/countries';
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';
import { environment } from '../../../../../environments/environment';

const { required } = Validators;

@Component({
  selector: 'ng-casto-common-create-card-personal-informations',
  templateUrl: './personal-informations.component.html'
})
export class CreateCardPersonalInformationsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isEmailSearched: boolean = false;
  @Input() customerId?: string;
  @Input() btnTemplate: TemplateRef<any>;
  @Input() defaultEmail?: string;

  cities: string[];
  prioCountries: Country[] = prioCountries;
  otherCountries: Country[] = otherCountries;

  emailLoading: boolean = false;
  emailSearched: string;
  emailExists: boolean = false;

  hasCard: boolean = false;

  customerInfo: CustomerKfOnlyDto;

  private isPublic: boolean;

  protected readonly MemberTypeCh = MemberTypeCh;
  protected readonly Context = Context;
  protected readonly environment = environment;

  constructor(private http: HttpClient,
    private colleagueCustomerService: ColleagueCustomerService,
    private customerService: CustomerService,
    private envService: EnvService) { }

  ngOnInit(): void {
    this.isPublic = this.envService.isPublic();

    let timeoutHandle: any;

    this.form.get('email').valueChanges.subscribe(x => {
      if (x !== this.emailSearched) {
        this.isEmailSearched = false;
        this.form.get('phoneNumber').setValue(null);
        this.form.get('civility').setValue(null);
        this.form.get('civility').disable();
        this.form.get('lastName').setValue(null);
        this.form.get('firstName').setValue(null);
        this.form.get('postalCode').setValue(null);
        this.form.get('city').setValue(null);
        this.form.get('houseNumber').setValue(null);
        this.form.get('address').setValue(null);
        this.form.get('country').disable();
        this.form.get('localityPoBox').setValue(null);
        this.form.get('buildingFloorStaircase').setValue(null);
        this.form.get('birthDate').setValue(null);
      } else {
        this.isEmailSearched = true;
        this.form.get('civility').enable();
        this.form.get('country').enable();
      }

      if (timeoutHandle) {
        window.clearTimeout(timeoutHandle);
      }

      timeoutHandle = setTimeout(() => {
        if (this.form.get('email').valid) {
          this.searchMail();
        }
      }, 1000);
    });

    if (!this.form.get('country').value) {
      this.form.get('country').setValue(this.prioCountries[0].value);
    }

    if (this.form.get('city').value && this.form.get('city').value.length > 0) {
      this.cities = [this.form.get('city').value];
    }

    this.form.get('postalCode').valueChanges.subscribe(x => {
      if (this.form.get('country').value === 'FR') {
        this.searchCity(x);
      }
    });

    if (this.defaultEmail || this.form.get('email').value) {
      this.searchMail();
    }
  }

  /**
   * Détermine si le champs est obligatoire ou non
   * @param id id du champ du formulaire
   * @returns boolean
   */
  isRequired(id: string): boolean {
    return this.form.controls[id]?.hasValidator(required);
  }

  /**
   * Fonction de recherche de l'adresse mail dans la base KF
   */
  searchMail() {
    if (this.form.controls['email'].valid && !this.isEmailSearched) {
      this.emailLoading = true;

      // Colleague
      if (!this.isPublic) {
        this.colleagueCustomerService.getList({
          maxResultCount: 1,
          skipCount: 0
        }, {
          mail: this.form.controls['email'].value
        }).subscribe(c => {
          if (c.totalCount > 0) {
            //L'adresse mail recherchée existe déjà en base
            this.emailExists = true;
            this.hasCard = c.items[0].cardNumber && c.items[0].cardNumber.length > 0;
            this.customerInfo = c.items[0];
            if (this.hasCard) {
              //Si l'adresse mail recherchée existe et a déjà une carte, on lance une erreur
              this.form.get('email').setErrors({ taken: true });
            } else {
              //Si l'adresse mail recherchée existe et n'a pas encore de carte, on remplie le formulaire des informations déjà connues
              this.form.get('phoneNumber').setValue(this.customerInfo.phoneNumber);
              this.form.get('civility').enable();
              this.form.get('civility').setValue(this.customerInfo.civility);
              this.form.get('lastName').setValue(this.customerInfo.name);
              this.form.get('firstName').setValue(this.customerInfo.firstName);
              this.form.get('houseNumber').setValue(this.customerInfo.houseNumber);
              this.form.get('address').setValue(this.customerInfo.address);
              this.form.get('postalCode').setValue(this.customerInfo.postalCode);
              this.form.get('companyName').setValue(this.customerInfo.companyName);
              this.form.get('memberType').setValue(this.customerInfo.companyName ? MemberTypeCh.Professionnel : MemberTypeCh.Particulier);
              this.form.get('city').setValue(this.customerInfo.city);
              this.form.get('country').enable();
            }
          } else {
            //L'adresse mail n'existe pas en base
            this.customerInfo = undefined;
            this.emailExists = false;
            this.hasCard = false;
            this.form.get('civility').enable();
            this.form.get('country').enable();
          }
          this.emailSearched = this.form.controls['email'].value;
          this.isEmailSearched = true;
          this.emailLoading = false;
        });
      } else if (!this.defaultEmail || this.defaultEmail !== this.form.get('email').value) {
        // Customer - on fait la recherche que s'il ne s'agit pas de l'adresse mail par défaut
        this.customerService.emailCanBeAssigned(this.form.get('email').value, this.customerId).subscribe({
          next: (response) => {
            if (response) {
              //L'adresse mail n'existe pas en base
              this.customerInfo = undefined;
              this.emailExists = false;
              this.hasCard = false;
              this.form.get('civility').enable();
              this.form.get('country').enable();
            } else {
              //Si l'adresse mail recherchée existe et a déjà une carte, on lance une erreur
              this.form.get('email').setErrors({ taken: true });
            }
            this.emailSearched = this.form.get('email').value;
            this.isEmailSearched = true;
            this.emailLoading = false;
          },
          error: (err) => {
            //Si l'adresse mail recherchée existe et a déjà une carte, on lance une erreur
            this.form.get('email').setErrors({ taken: true });
            this.emailLoading = false;
          }
        });
      } else {
        this.emailSearched = this.form.get('email').value;
        this.isEmailSearched = true;
        this.emailLoading = false;
      }
    } else {
      this.form.get('civility').enable();
      this.form.get('country').enable();
    }
  }

  searchCity(text: string): void {
    if (this.isFrenchPostalCodeValid(text)) {
      this.http.get('https://apicarto.ign.fr/api/codes-postaux/communes/' + text)
        .subscribe({
          next: (c: any[]) => {
            this.cities = c.map(city => city.libelleAcheminement.replace('(', '').replace(')', ''));

            let currentCity = this.form.get('city').value;
            let city = this.cities[0];
            if (currentCity) {
              let tmp = this.cities.find(ci => ci.localeCompare(currentCity, 'fr', { sensitivity: 'base' }) === 0);
              if (tmp) {
                city = tmp;
              }
            }

            this.form.get('city').setValue(city);

            if (this.form.get('postalCode').hasError('notExist')) {
              this.form.get('postalCode').errors.setErrors({ notExist: null });
            }
          },
          error: (err) => {
            console.error(err);
            this.cities = [];
            this.form.get('city').setValue('');
            this.form.get('postalCode').setErrors({ notExist: true });
          }
        });
    } else {
      this.cities = [];
      this.form.get('city').setValue('');
    }
  }

  private isFrenchPostalCodeValid(cp: string): boolean {
    return cp && cp.length > 0 && regexPostalCodeFr.test(cp) && this.form.get('country').value === 'FR';
  }
}
