<div class="result-table" *ngIf="orderHistory && orderHistory.totalCount > 0 && !loading">
  <ngx-datatable [rows]="orderHistory.items" [count]="8" default (activate)="onActivate($event)">
    <ngx-datatable-column name="Nom du magasin" prop="storeIdAssociated" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.storeIdAssociated | slice:-4 }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Date" prop="activityDate" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.activityDate | date:'dd/MM/yyyy' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Nombre d'articles" prop="nbProducts" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.nbProducts | number }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Montant" prop="totalPrice" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.totalPrice | currency:'EUR' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Ticket" prop="ticketNumberAssociated" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.ticketNumberAssociated }}
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>

<div class="text-center" *ngIf="loading">
  <ng-casto-loader></ng-casto-loader>
</div>
<div class="text-center" *ngIf="(!orderHistory || orderHistory.totalCount <= 0) && !loading">
  <h2 class="m-3">Aucune donnée disponible</h2>
</div>
