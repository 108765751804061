export const regexMail = /^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\.[a-z]{2,3}$/;

export const regexCastoCardNumber = /^913\d{16}$/;

export const regexPhoneNumber = /^(0[6-9](?!0{8})\d{8})|(\+\d{7,12})?$/;
// On souhaite une regex plus large pour la recherche de numéro de téléphone que pour la définir
export const regexSearchPhoneNumber = /^([\d+]?[\d]{7,12})?$/;

export const regexMailOrCastoCardNumberOrPhoneNumber = /^(?:([\d+]?[\d]{7,12})|(913\d{16})|([A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\.[a-z]{2,3}))$/;

export const regexPostalCode = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;

export const regexPassword = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}/;

export const regexPostalCodeFr = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
