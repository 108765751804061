import { GiftCardDto, OfferCouponDto } from '@proxy/dto-common-website/fidelity-card/models';
import { GiftCardChDto, OfferCouponChDto } from '@proxy/cheetah/client/dto';

export class Voucher {
  title: string;
  label?: string;
  active: boolean;
  dateStart: Date;
  dateEnd: Date;
  isOfferCoupon: boolean;
  code: string;

  constructor(offerCoupon? : OfferCouponDto,
              giftCard?: GiftCardDto,
              offerCouponCh? : OfferCouponChDto,
              giftCardCh?: GiftCardChDto) {
    if (offerCouponCh) {
      this.title = offerCouponCh.name;
      this.label = 'de fidélité';
      this.active = offerCouponCh.usable;
      this.dateStart = new Date(offerCouponCh.usabilityStartDate);
      this.dateEnd = new Date(offerCouponCh.usabilityEndDate);
      this.code = offerCouponCh.code;
      this.isOfferCoupon = true;
    }

    if (giftCardCh) {
      this.title = giftCardCh.title;
      this.label = giftCardCh.label;
      this.active = giftCardCh.active;
      this.dateStart = new Date(giftCardCh.usabilityStartDate);
      this.dateEnd = new Date(giftCardCh.usabilityEndDate);
      this.isOfferCoupon = false;
    }

    if (offerCoupon) {
      this.title = offerCoupon.name;
      this.label = 'de fidélité';
      this.active = offerCoupon.usable;
      this.dateStart = new Date(offerCoupon.beginUsability);
      this.dateEnd = new Date(offerCoupon.endUsability);
      this.code = offerCoupon.code;
      this.isOfferCoupon = true;
    }

    if (giftCard) {
      this.title = giftCard.title;
      this.label = giftCard.label;
      this.active = giftCard.active;
      this.dateStart = new Date(giftCard.dateStart);
      this.dateEnd = new Date(giftCard.dateEnd);
      this.isOfferCoupon = false;
    }
  }
}