import { Component, Input, OnInit } from '@angular/core';
import { FidelityCardService } from '@proxy/user-casto/fidelity-card';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { PointsHistoryChDto } from '@proxy/cheetah/client/dto';
import { ActivityTypePointCh } from '@proxy/cheetah/client/enum';
import { Subject } from 'rxjs';
import { SearchHistoryDto } from '@proxy/dto-common-website';

@Component({
  selector: 'ng-casto-common-customer-points-history',
  templateUrl: './customer-points-history.component.html',
  providers: [ListService]
})
export class CustomerPointsHistoryComponent implements OnInit {
  @Input() customerId: string;
  @Input() resetPointHistory: Subject<boolean>;

  customerPoints: PagedResultDto<PointsHistoryChDto> = { items: [], totalCount: 0 };

  loading: boolean = false;

  protected readonly ActivityTypePointCh = ActivityTypePointCh;

  constructor(public readonly list: ListService<SearchHistoryDto>,
              private fidelityCardService: FidelityCardService) {}

  ngOnInit() {
    this.loading = true;

    const bookStreamCreator = (query) => this.fidelityCardService.getPointsHistory({
      ...query,
      customerId: this.customerId
    });

    this.list.hookToQuery(bookStreamCreator).subscribe({
      next: (response) => {
        this.customerPoints = response;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });

    this.resetPointHistory.subscribe(() => {
      this.loading = true;

      // j'ai mis un setTimeout pour éviter de requêter trop vite l'endpoint de l'historique après une demande de régularisation
      setTimeout(() => {
        this.list.get();
        this.loading = false;

      }, 500);
    });
  }
}
