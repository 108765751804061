export const prioCountries: Country[] = [
  {value: '', label: ''},
  {value: 'FR', label: 'France'},
  {value: 'BE', label: 'Belgique'},
  {value: 'CH', label: 'Suisse'},
  {value: 'LU', label: 'Luxembourg'},
  {value: 'IT', label: 'Italie'},
  {value: 'DE', label: 'Allemagne'},
  {value: 'SP', label: 'Espagne'}
]

export const otherCountries: Country[] = [
  {value: 'AF', label: "Afghanistan"},
  {value: 'ZA', label: "Afrique du Sud"},
  {value: 'AL', label: "Albanie"},
  {value: 'DZ', label: "Algérie"},
  {value: 'AD', label: "Andorre"},
  {value: 'AO', label: "Angola"},
  {value: 'AI', label: "Anguilla"},
  {value: 'AQ', label: "Antarctique"},
  {value: 'AG', label: "Antigua-et-Barbuda"},
  {value: 'SX', label: "Antilles néerlandaises"},
  {value: 'SA', label: "Arabie saoudite"},
  {value: 'AR', label: "Argentine"},
  {value: 'AM', label: "Arménie"},
  {value: 'AW', label: "Aruba"},
  {value: 'AU', label: "Australie"},
  {value: 'AT', label: "Autriche"},
  {value: 'AZ', label: "Azerbaïdjan"},
  {value: 'BS', label: "Bahamas"},
  {value: 'BH', label: "Bahreïn"},
  {value: 'BD', label: "Bangladesh"},
  {value: 'BB', label: "Barbade"},
  {value: 'BY', label: "Bélarus"},
  {value: 'BZ', label: "Belize"},
  {value: 'BJ', label: "Bénin"},
  {value: 'BM', label: "Bermudes"},
  {value: 'BT', label: "Bhoutan"},
  {value: 'BO', label: "Bolivie"},
  {value: 'BA', label: "Bosnie-Herzégovine"},
  {value: 'BW', label: "Botswana"},
  {value: 'BR', label: "Brésil"},
  {value: 'BN', label: "Brunéi Darussalam"},
  {value: 'BG', label: "Bulgarie"},
  {value: 'BF', label: "Burkina Faso"},
  {value: 'BI', label: "Burundi"},
  {value: 'KH', label: "Cambodge"},
  {value: 'CM', label: "Cameroun"},
  {value: 'CA', label: "Canada"},
  {value: 'CV', label: "Cap-Vert"},
  {value: 'CL', label: "Chili"},
  {value: 'CN', label: "Chine"},
  {value: 'CY', label: "Chypre"},
  {value: 'CO', label: "Colombie"},
  {value: 'KM', label: "Comores"},
  {value: 'CG', label: "Congo-Brazzaville"},
  {value: 'KP', label: "Corée du Nord"},
  {value: 'KR', label: "Corée du Sud"},
  {value: 'CR', label: "Costa Rica"},
  {value: 'CI', label: "Côte d'Ivoire"},
  {value: 'HR', label: "Croatie"},
  {value: 'CU', label: "Cuba"},
  {value: 'DK', label: "Danemark"},
  {value: 'DJ', label: "Djibouti"},
  {value: 'DM', label: "Dominique"},
  {value: 'EG', label: "Égypte"},
  {value: 'SV', label: "El Salvador"},
  {value: 'AE', label: "Émirats arabes unis"},
  {value: 'EC', label: "Équateur"},
  {value: 'ER', label: "Érythrée"},
  {value: 'EE', label: "Estonie"},
  {value: 'VA', label: "État de la Cité du Vatican"},
  {value: 'FM', label: "États fédérés de Micronésie"},
  {value: 'US', label: "États-Unis"},
  {value: 'ET', label: "Éthiopie"},
  {value: 'FJ', label: "Fidji"},
  {value: 'FI', label: "Finlande"},
  {value: 'GA', label: "Gabon"},
  {value: 'GM', label: "Gambie"},
  {value: 'GE', label: "Géorgie"},
  {value: 'GS', label: "Géorgie du Sud et les îles Sandwich du Sud"},
  {value: 'GH', label: "Ghana"},
  {value: 'GI', label: "Gibraltar"},
  {value: 'GR', label: "Grèce"},
  {value: 'GD', label: "Grenade"},
  {value: 'GL', label: "Groenland"},
  {value: 'GP', label: "Guadeloupe"},
  {value: 'GU', label: "Guam"},
  {value: 'GT', label: "Guatemala"},
  {value: 'GG', label: "Guernesey"},
  {value: 'GN', label: "Guinée"},
  {value: 'GQ', label: "Guinée équatoriale"},
  {value: 'GW', label: "Guinée-Bissau"},
  {value: 'GY', label: "Guyana"},
  {value: 'GF', label: "Guyane française"},
  {value: 'HT', label: "Haïti"},
  {value: 'HN', label: "Honduras"},
  {value: 'HU', label: "Hongrie"},
  {value: 'BV', label: "Île Bouvet"},
  {value: 'CX', label: "Île Christmas"},
  {value: 'NF', label: "Île Norfolk"},
  {value: 'AX', label: "Îles Åland"},
  {value: 'KY', label: "Îles Caïmans"},
  {value: 'CC', label: "Îles Cocos - Keeling"},
  {value: 'CK', label: "Îles Cook"},
  {value: 'FO', label: "Îles Féroé"},
  {value: 'HM', label: "Îles Heard et MacDonald"},
  {value: 'MP', label: "Îles Mariannes du Nord"},
  {value: 'MH', label: "Îles Marshall"},
  {value: 'UM', label: "Îles Mineures Éloignées des États-Unis"},
  {value: 'SB', label: "Îles Salomon"},
  {value: 'TC', label: "Îles Turks et Caïques"},
  {value: 'VG', label: "Îles Vierges britanniques"},
  {value: 'VI', label: "Îles Vierges des États-Unis"},
  {value: 'IN', label: "Inde"},
  {value: 'ID', label: "Indonésie"},
  {value: 'IQ', label: "Irak"},
  {value: 'IR', label: "Iran"},
  {value: 'IE', label: "Irlande"},
  {value: 'IS', label: "Islande"},
  {value: 'IL', label: "Israël"},
  {value: 'JM', label: "Jamaïque"},
  {value: 'JP', label: "Japon"},
  {value: 'JE', label: "Jersey"},
  {value: 'JO', label: "Jordanie"},
  {value: 'KZ', label: "Kazakhstan"},
  {value: 'KE', label: "Kenya"},
  {value: 'KG', label: "Kirghizistan"},
  {value: 'KI', label: "Kiribati"},
  {value: 'KW', label: "Koweït"},
  {value: 'LA', label: "Laos"},
  {value: 'LS', label: "Lesotho"},
  {value: 'LV', label: "Lettonie"},
  {value: 'LB', label: "Liban"},
  {value: 'LR', label: "Libéria"},
  {value: 'LY', label: "Libye"},
  {value: 'LI', label: "Liechtenstein"},
  {value: 'LT', label: "Lituanie"},
  {value: 'MK', label: "Macédoine"},
  {value: 'MG', label: "Madagascar"},
  {value: 'MY', label: "Malaisie"},
  {value: 'MW', label: "Malawi"},
  {value: 'MV', label: "Maldives"},
  {value: 'ML', label: "Mali"},
  {value: 'MT', label: "Malte"},
  {value: 'MA', label: "Maroc"},
  {value: 'MQ', label: "Martinique"},
  {value: 'MU', label: "Maurice"},
  {value: 'MR', label: "Mauritanie"},
  {value: 'YT', label: "Mayotte"},
  {value: 'MX', label: "Mexique"},
  {value: 'MD', label: "Moldavie"},
  {value: 'MC', label: "Monaco"},
  {value: 'MN', label: "Mongolie"},
  {value: 'ME', label: "Monténégro"},
  {value: 'MS', label: "Montserrat"},
  {value: 'MZ', label: "Mozambique"},
  {value: 'MM', label: "Myanmar"},
  {value: 'NA', label: "Namibie"},
  {value: 'NR', label: "Nauru"},
  {value: 'NI', label: "Népal"},
  {value: 'NE', label: "Nicaragua"},
  {value: 'NE', label: "Niger"},
  {value: 'NG', label: "Nigéria"},
  {value: 'NU', label: "Niue"},
  {value: 'NO', label: "Norvège"},
  {value: 'NC', label: "Nouvelle-Calédonie"},
  {value: 'NZ', label: "Nouvelle-Zélande"},
  {value: 'OM', label: "Oman"},
  {value: 'UG', label: "Ouganda"},
  {value: 'UZ', label: "Ouzbékistan"},
  {value: 'PK', label: "Pakistan"},
  {value: 'PW', label: "Palaos"},
  {value: 'PA', label: "Panama"},
  {value: 'PG', label: "Papouasie-Nouvelle-Guinée"},
  {value: 'PY', label: "Paraguay"},
  {value: 'NL', label: "Pays-Bas"},
  {value: 'PE', label: "Pérou"},
  {value: 'PH', label: "Philippines"},
  {value: 'PN', label: "Pitcairn"},
  {value: 'PL', label: "Pologne"},
  {value: 'PF', label: "Polynésie française"},
  {value: 'PR', label: "Porto Rico"},
  {value: 'PT', label: "Portugal"},
  {value: 'QA', label: "Qatar"},
  {value: 'HK', label: "R.A.S. chinoise de Hong Kong"},
  {value: 'MO', label: "R.A.S. chinoise de Macao"},
  {value: 'CF', label: "République centrafricaine"},
  {value: 'CD', label: "République démocratique du Congo"},
  {value: 'DO', label: "République dominicaine"},
  {value: 'CZ', label: "République tchèque"},
  {value: 'RE', label: "Réunion"},
  {value: 'RO', label: "Roumanie"},
  {value: 'GB', label: "Royaume-Uni"},
  {value: 'RU', label: "Russie"},
  {value: 'RW', label: "Rwanda"},
  {value: 'EH', label: "Sahara occidental"},
  {value: 'BL', label: "Saint-Barthélémy"},
  {value: 'KN', label: "Saint-Kitts-et-Nevis"},
  {value: 'SM', label: "Saint-Marin"},
  {value: 'MF', label: "Saint-Martin"},
  {value: 'PM', label: "Saint-Pierre-et-Miquelon"},
  {value: 'VC', label: "Saint-Vincent-et-les Grenadines"},
  {value: 'SH', label: "Sainte-Hélène"},
  {value: 'LC', label: "Sainte-Lucie"},
  {value: 'WS', label: "Samoa"},
  {value: 'ST', label: "Sao Tomé-et-Principe"},
  {value: 'SN', label: "Sénégal"},
  {value: 'RS', label: "Serbie"},
  {value: 'ME', label: "Serbie-et-Monténégro"},
  {value: 'SC', label: "Seychelles"},
  {value: 'SL', label: "Sierra Leone"},
  {value: 'SG', label: "Singapour"},
  {value: 'SK', label: "Slovaquie"},
  {value: 'SI', label: "Slovénie"},
  {value: 'SO', label: "Somalie"},
  {value: 'SD', label: "Soudan"},
  {value: 'LK', label: "Sri Lanka"},
  {value: 'SE', label: "Suède"},
  {value: 'SR', label: "Suriname"},
  {value: 'SJ', label: "Svalbard et Île Jan Mayen"},
  {value: 'SY', label: "Syrie"},
  {value: 'TJ', label: "Tadjikistan"},
  {value: 'TW', label: "Taïwan"},
  {value: 'TZ', label: "Tanzanie"},
  {value: 'TD', label: "Tchad"},
  {value: 'TF', label: "Terres australes françaises"},
  {value: 'IO', label: "Territoire britannique de l'océan Indien"},
  {value: 'PS', label: "Territoire palestinien"},
  {value: 'TH', label: "Thaïlande"},
  {value: 'TL', label: "Timor oriental"},
  {value: 'TG', label: "Togo"},
  {value: 'TK', label: "Tokelau"},
  {value: 'TO', label: "Tonga"},
  {value: 'TT', label: "Trinité-et-Tobago"},
  {value: 'TN', label: "Tunisie"},
  {value: 'TM', label: "Turkménistan"},
  {value: 'TR', label: "Turquie"},
  {value: 'TV', label: "Tuvalu"},
  {value: 'UA', label: "Ukraine"},
  {value: 'UY', label: "Uruguay"},
  {value: 'VU', label: "Vanuatu"},
  {value: 'VE', label: "Venezuela"},
  {value: 'VN', label: "Viêt Nam"},
  {value: 'WF', label: "Wallis-et-Futuna"},
  {value: 'YE', label: "Yémen"},
  {value: 'ZM', label: "Zambie"},
  {value: 'ZW', label: "Zimbabwe"}
]

export interface Country {
  value: string;
  label: string;
}
