import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MODAL, ModalService } from 'src/app/modals/modal.service';
import { Context } from 'src/app/utils/context';
import { OrderHistoryService as ColleagueOrderHistoryService } from '@proxy/user-casto/order-history';
import { OrderHistoryService } from 'src/publicproxy/src/proxy/public/order-history';
import { PagedResultDto } from '@abp/ng.core';
import { ActivityPurchaseChDto } from '@proxy/cheetah/client/dto';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'ng-casto-common-customer-order-history',
  templateUrl: './customer-order-history.component.html'
})
export class CustomerOrderHistoryComponent implements OnInit {

  orderHistory = { items: [], totalCount: 0 } as PagedResultDto<ActivityPurchaseChDto>;
  @Input() customerId = '';
  @Input() resume:boolean = false;
  @Input() context: Context = Context.Colleague;
  loading: boolean = false;
  token: string;

  constructor(
    private orderHistoryService: OrderHistoryService,
    private colleagueOrderHistoryService: ColleagueOrderHistoryService,
    private router: Router,
    private modalService: ModalService,
    private storageService: StorageService) {}

  ngOnInit() {
    this.loading = true;
    switch(this.context){
      case Context.Colleague:
        this.colleagueOrderHistoryService.getOrderHistory({customerId: this.customerId, pageNumber: 1, resultPerPage: 8}).subscribe({
          next: (response) => {
            this.orderHistory = response;
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
          }
        })
        break;
      case Context.Customer:
        this.token = this.storageService.getToken()?.access_token;

        this.orderHistoryService.getOrderHistory({customerId: this.customerId, pageNumber: 1, resultPerPage: 8}, this.token).subscribe({
          next: (response) => {
            this.orderHistory = response;
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
          }
        })
    }
  }

  onActivate(event: any) {
    if(event.type === 'click'){
      this.modalService.display(MODAL.ORDER_DETAILS, {
        customerId: this.customerId,
        orderId: event.row.orderIdAssociated,
        registeredToken: this.token,
        context: this.context
      });
    }
  }
}
