<div class="form-card form-card-large" *ngIf="form" [formGroup]="form"
  [ngClass]="{disabled: form.value?.memberType === ''}">
  <h2 class="form-title">
    <span class="icon"></span>
    <span *ngIf="!isPublic">Informations personnelles</span>
    <span *ngIf="isPublic">Vos coordonnées</span>
  </h2>
  <div class="form-group">

    <!-- Mail -->
    <div class="form-row">
      <div class="form-col">
        <label for="mail" class="required">Adresse email</label>
        <div class="casto-input-group">
          <input class="casto-input" formControlName="email" type="email" name="mail" id="mail">
          <button [disabled]="!this.form.controls['email'].valid" (click)="searchMail()">
            <div>
              <div class="spinner-border" role="status" *ngIf="emailLoading"></div>
              <svg *ngIf="!emailLoading && !isEmailSearched">
                <use xlink:href="assets/img/icons.svg#icon-search"></use>
              </svg>
              <svg *ngIf="isEmailSearched">
                <use xlink:href="assets/img/icons.svg#icon-check"></use>
              </svg>
            </div>
          </button>
        </div>
        <small class="error" *ngIf="form.get('email').errors?.taken">L'e-mail est déjà utilisé</small>
      </div>
    </div>

    <!-- Civility -->
    <div class="form-row form-inline">
      <label class="required">Civilité</label>

      <input type="radio" name="civility" id="monsieur" [value]="0" [ngClass]="{readonly: !isEmailSearched}"
        formControlName="civility">
      <label for="monsieur">M.</label>

      <input type="radio" name="civility" id="madame" [value]="1" [ngClass]="{readonly: !isEmailSearched}"
        formControlName="civility">
      <label for="madame">Mme</label>

      <input type="radio" name="civility" id="mademoiselle" [value]="2" [ngClass]="{readonly: !isEmailSearched}"
        formControlName="civility">
      <label for="mademoiselle">Mlle</label>
    </div>

    <!-- Last Name -->
    <div class="form-row">
      <div class="form-col">
        <label for="lastName" class="required">Nom</label>
        <input class="casto-input" type="text" name="lastName" id="lastName" [readonly]="!isEmailSearched"
          formControlName="lastName">
      </div>
    </div>

    <!-- First Name -->
    <div class="form-row">
      <div class="form-col">
        <label for="firstName" class="required">Prénom</label>
        <input class="casto-input" type="text" name="firstName" id="firstName" [readonly]="!isEmailSearched"
          formControlName="firstName">
      </div>
    </div>

    <!-- Phone Number -->
    <div class="form-row">
      <div class="form-col">
        <label for="phoneNumber" [ngClass]="{required: isRequired('phoneNumber')}">Mobile</label>
        <input class="casto-input" [readonly]="!isEmailSearched" formControlName="phoneNumber" type="text"
          name="phoneNumber" id="phoneNumber">
        <small class="error" *ngIf="form.get('phoneNumber').errors?.['pattern']">Le numéro saisie est incorrect</small>
      </div>
    </div>

    <!-- Company Name -->
    <div class="form-row" *ngIf="form.getRawValue()?.memberType === MemberTypeCh.Professionnel">
      <div class="form-col">
        <label for="companyName" class="required">Raison sociale</label>
        <input class="casto-input" type="text" name="companyName" id="companyName" [readonly]="!isEmailSearched"
          formControlName="companyName">
      </div>
      <div class="form-col"></div>
    </div>

    <!-- Country -->
    <div class="form-row">
      <div class="form-col">
        <label for="country" [ngClass]="{required: isRequired('country')}">Pays</label>
        <select class="casto-input" name="country" formControlName="country" id="country">
          <option *ngFor="let country of prioCountries" [value]="country.value">{{ country.label }}</option>
          <option disabled>__________</option>
          <option *ngFor="let country of otherCountries" [value]="country.value">{{ country.label }}</option>
        </select>
      </div>
    </div>

    <!-- Address -->
    <div class="form-row form-row-inline-sm">
      <div class="form-col">
        <label for="houseNumber" [ngClass]="{required: isRequired('houseNumber')}">N° de rue</label>
        <input class="casto-input" maxlength="10" type="text" name="houseNumber" id="houseNumber"
          [readonly]="!isEmailSearched" formControlName="houseNumber">
      </div>
      <div class="form-col form-col-large">
        <label for="address" [ngClass]="{required: isRequired('address')}">Rue</label>
        <input class="casto-input" [readonly]="!isEmailSearched" formControlName="address" type="text" name="address"
          id="address">
      </div>
    </div>

    <div class="form-row form-row-inline-sm">
      <!-- Postal Code -->
      <div class="form-col">
        <label for="postal" [ngClass]="{required: isRequired('postalCode')}">Code postal</label>
        <input class="casto-input" type="text" name="postal" id="postal" [readonly]="!isEmailSearched"
          formControlName="postalCode">
      </div>

      <!-- City -->
      <div class="form-col form-col-large">
        <label for="city" [ngClass]="{required: isRequired('city')}">Ville</label>
        <select class="casto-input" [disabled]="!cities || cities.length === 0 || !isEmailSearched" name="city"
          id="city" formControlName="city" *ngIf="form.value?.country === 'FR'">
          <option *ngFor="let ci of cities" [value]="ci">{{ci}}</option>
        </select>
        <input class="casto-input" type="text" name="city" [readonly]="!isEmailSearched" formControlName="city"
          *ngIf="form.value?.country !== 'FR'">
      </div>

    </div>

    <small class="form-row-error error"
      *ngIf="form.get('postalCode').errors?.notExist || form.get('postalCode').errors?.pattern">
      Le code postal saisie est incorrect
    </small>

    <div class="form-row form-row-inline-sm">
      <div class="form-col">
        <label for="buildingFloorStaircase">Bâtiment, étage, escalier</label>
        <input class="casto-input" formControlName="buildingFloorStaircase" type="text" name="buildingFloorStaircase"
          id="buildingFloorStaircase">
      </div>
      <div class="form-col">
        <label for="localityPoBox">Lieu-dit, boîte postale, etc...</label>
        <input class="casto-input" type="text" name="localityPoBox" id="localityPoBox" formControlName="localityPoBox">
      </div>
    </div>

    <!-- Birth Date -->
    <div class="form-row">
      <div class="form-col">
        <label for="birthDate">Date de naissance</label>
        <input type="date" name="birthDate" id="birthDate" formControlName="birthDate" class="casto-input">
      </div>
      <div class="form-col"></div>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="btnTemplate;"></ng-container>
</div>